import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import HomeIcon from '@material-ui/icons/Home';
import Navbar from '../components/navbar/navbarvn';
import Banner from '../components/lien-he/banner';
import Footer from '../components/homepagevn/Footer';
import Mailchimp from '../components/contact-form';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  contactItem: {
    marginBottom: '10px !important',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid #ddd',
  },
  contactLastItem: {
    marginBottom: '10px !important',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contactContent: {
    fontSize: '1.14rem',
    lineHeight: '1.5',
    textDecoration: 'none',
    paddingLeft: '2rem',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
  },
}));

export default function ContactEnglish() {
  const classes = useStyles();
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title> LIÊN HỆ</title>
      </Helmet>
      <Navbar />
      <Banner />
      <Container style={{ marginBottom: '8rem', marginTop: '8rem' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={5} className={classes.container}>
            <Typography variant="h4" component="h2" style={{ marginBottom: '1rem' }}>
              <strong> Thông tin liên lạc </strong>
            </Typography>
            <div className={classes.contactItem}>
              <MailOutlineIcon style={{ color: '#f2ae1c' }} />
              <Typography className={classes.contactContent}>
                <strong> EMAIL </strong>
                <br />
                trucnh@chinhdaisteel.com
              </Typography>
            </div>
            <div className={classes.contactItem}>
              <PhoneInTalkIcon style={{ color: '#f2ae1c' }} />
              <Typography className={classes.contactContent}>
                <strong> ĐIỆN THOẠI </strong>
                <br />
                (+84) 913 236 789 / (+84) 976 208 688
              </Typography>
            </div>
            <div className={classes.contactItem}>
              <AccessTimeIcon style={{ color: '#f2ae1c' }} />
              <Typography className={classes.contactContent}>
                <strong> GIỜ LÀM VIỆC  </strong>
                <br />
                Sáng: 8h30 - 12h00, Chiều: 13h00 - 17h30
              </Typography>
            </div>
            <div className={classes.contactLastItem}>
              <HomeIcon style={{ color: '#f2ae1c' }} />
              <Typography className={classes.contactContent}>
                <strong> ĐỊA CHỈ </strong>
                <br />
                Công ty TNHH Công Nghiệp Chính Đại, Thôn Hoằng, xã Lạc Đạo,
                huyện Văn Lâm, tỉnh Hưng Yên
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={7}>
            <Mailchimp />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}
